import styled, { css } from "styled-components";
export const $Footer = styled.footer`
  width: 100%;
  height: auto;

  .info {
    height: auto;
    width: 100%;
    background: url("../../../SVG/angles.svg") no-repeat center;
    @media only screen and (max-width: 768px) {
      background: url("../../../SVG/carte-vert.svg") no-repeat;
      background-size: cover;
    }
    @media only screen and (min-width: 768px) {
      min-width: 768px;
    }
    .inner {
      height: 100%;
      padding: 2em;
      width: 80%;
      min-width: 764px;
      position: relative;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      align-items: center;

      @media only screen and (max-width: 768px) {
        width: 100%;
        min-width: 425px;
      }
      @media only screen and (max-width: 500px) {
        min-width: 0px;
      }

      div {
        height: 100%;
        /* padding: 0 1.5em; */

        @media only screen and (max-width: 1024px) {
          padding: 0;
        }
      }

      .container {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: 500px) {
          flex-direction: column;
          align-items: center;
        }
      }
      .contact {
        width: 34%;
        margin: 0 1em;
        text-align: left;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 768px) {
          width: 45%;
        }
        @media only screen and (max-width: 500px) {
          width: 80%;

          margin: 1em;
        }
        h4 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 1em;
        }
        span {
          font-size: 16px;
        }
        .contact-container {
          display: flex;

          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
          .contact-list {
            @media only screen and (min-width: 769px) {
              padding: 1em;
            }
            a {
              padding: 0.5em 0;
              display: flex;
              /* justify-content: center; */
              align-items: center;
              color: #fff;

              span {
                padding: 0 0.5em;
              }
              svg {
                width: 1.5em;
                height: 1.5em;
                /* padding: 0.2em; */
              }
            }
          }
        }
      }

      .horaire {
        width: 34%;
        margin: 0 1em;

        color: #fff;
        display: block;
        @media only screen and (max-width: 768px) {
          width: 45%;
        }
        @media only screen and (max-width: 500px) {
          width: 80%;

          margin: 1em;
        }
        h4 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 1em;
        }
        span {
          font-size: 16px;
        }
        div {
          padding: 0.3em 0;
        }
      }
    }
  }

  .map {
    width: 100%;
    cursor: pointer;
    height: 25em;
    background: url("../../../map.jpg") no-repeat center top;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .innerText {
      font-size: 2.5em;
      font-weight: 600;
      color: #fff;
      z-index: 1;
    }
    .Overlay {
      background-color: #000;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.55;
    }
  }

  .copyright {
    text-align: center;
    height: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #232e2d;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
`;

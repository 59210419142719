const NosUsine = [
  {
    ville: "Saint-Félicien (Scierie)",
    sousPageIndex: "Saint-Felicien",
    address: "900, boulevard Hamel, Saint-Félicien (Québec) G8K 2X4",
    goToGoogle:
      "https://www.google.ca/maps/dir//900+Boulevard+Hamel,+Saint-F%C3%A9licien,+QC+G8K+2X4/@48.6415334,-72.4461744,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc207b8efa71897:0x154626a96c2039e4!2m2!1d-72.4439857!2d48.6415299!3e0",
    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    imageSwipper: ["", "", "", "", ""],
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "La Doré",
    sousPageIndex: "La-Dore",
    address: "5850, avenue des Jardins, La Doré (Québec) G8J 1B4",
    goToGoogle:
      "https://www.google.ca/maps/dir//5850+Avenue+des+Jardins,+La+Dor%C3%A9,+QC+G8J+1B2/@48.7165225,-72.6478604,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9e0146ce14593:0x6d822fcfb01103de!2m2!1d-72.643483!2d48.7165155!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Normandin",
    sousPageIndex: "Normandin",
    address: "1165, rue Industrielle, Normandin (Québec) G8M 4S9",
    goToGoogle:
      "https://www.google.ca/maps/dir//1165+Rue+Industrielle,+Normandin,+Qu%C3%A9bec+G8M+4S9,+Canada/@48.8293908,-72.5211239,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9e6397cad3b43:0x3985a43625900533!2m2!1d-72.5167465!2d48.8293838!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Mistassini",
    sousPageIndex: "Mistassini",
    address: "200, rue De Quen, Dolbeau-Mistassini (Québec) G8L 5M8",
    goToGoogle:
      "https://www.google.ca/maps/dir//200+Rue+de+Quen,+Dolbeau-Mistassini,+Qu%C3%A9bec+G8L+5M8,+Canada/@48.8833343,-72.271906,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9f3ff57eb6f6f:0x228f611fd67400e4!2m2!1d-72.201866!2d48.8832426!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Girardville",
    sousPageIndex: "Girardville",
    address: "2250, rang Saint-Joseph Nord, Girardville (Québec) G0W 1R0",
    goToGoogle:
      "https://www.google.ca/maps/dir//2250+Rang+Saint+Joseph+Nord,+Girardville,+Qu%C3%A9bec+G0W+1R0,+Canada/@49.1266395,-72.5993328,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ce9ba367507710f:0x2d231494e51a7309!2m2!1d-72.5949554!2d49.1266326!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Saint-Thomas",
    sousPageIndex: "Saint-Thomas",
    address: "300, avenue du Moulin, Saint-Thomas-Didyme (Québec) G0W 1P0",
    goToGoogle:
      "https://www.google.ca/maps/dir//300+Route+du+Moulin,+Saint-Thomas-Didyme,+QC/@48.9245505,-72.6374028,16z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x4ce9c38fe5540487:0xd6448c2de6b281fb!2m2!1d-72.6330254!2d48.9245506!3e0",
    paragrapheList: [
      {
        text:
          "L’usine de Produits forestiers Résolu de St-Thomas Didyme au Lac Saint-Jean est dotée d’équipements à la fine pointe qui se spécialise dans le sciage du bois, le séchage et le rabotage. L’épinette noire, l’épinette blanche, le sapin baumier et le pin gris, tous récoltés localement, constituent la matière première transformée dans cette usine. Les produits qui en découlent sont destinés aux secteurs de la construction et de la production d’énergie."
      },
      {
        text:
          "L’usine de St-Thomas se distingue par l’engagement de ses employés et leur fort sentiment d’appartenance. Ceux-ci ont à cœur la réussite de leur usine et cela se traduit par une volonté réelle de s'entraider et de se démarquer. L’usine bénéficie d’ailleurs d’une feuille de route exemplaire au niveau de la sécurité au travail comptant fièrement plus de sept années sans accident."
      },
      {
        text:
          "Journaliers de production, opérateurs de machinerie lourde, mécaniciens, techniciens électrique et de procédés, opérateurs d’équipement de scierie et de rabotage, ingénieurs et personnel administratif constituent les nombreux corps de métiers à qui l’on doit la performance et le dynamisme de l’usine de St-Thomas."
      }
    ],
    operationsList: [
      {
        title: "Le sciage",
        text:
          "On y transforme des billes de 9’ en bois de construction brut comme les planches de 1X3, 2X2, 2X3, 2X4 et 2X6 destinées à la seconde transformation ou aux produits de construction. L’usine de sciage génère aussi des volumes de copeaux qui servent à la production de produits de pâtes et papiers, ainsi que des volumes de sciures nécessaires à la production d’énergie (granules de bois) ou de panneaux de particules (construction)."
      },
      {
        title: "Le séchage (ou les séchoirs)",
        text:
          "On y compte trois séchoirs et une installation de production de vapeur générée et alimentée à partir de nos propres sous-produits. Le bois, une fois séché, est destiné aux marchés de la construction et à la seconde transformation."
      },
      {
        title: "Le rabotage",
        text:
          "Notre département de rabotage permet de passer d’un bois « brut de sciage » à un bois lisse avec des faces planes et une épaisseur contrôlée. Le bois de 8’ et 9’ scié dans toutes les usines de Produits forestiers Résolu au Lac St-Jean et à l’extérieur de la région y est raboté. Il est par la suite transformé pour répondre aux besoins de nos marchés situés au Québec, en Ontario et aux États-Unis. Certains sont redirigés vers d’autres installations du groupe pour la fabrication de produits structuraux et de seconde transformation. On y génère aussi un sous-produit, la planure, qui sert à la production d’énergie ou à la fabrication de panneaux."
      }
    ],
    directeurMessage:
      "« Je vous invite à joindre une équipe fière de son usine, et ayant une culture de gens impliqués ! Je me suis joint à cette équipe il y a moins de 2 ans et je constate tous les jours cette implication, cette culture. Venez nous rejoindre, vous ne le regretterez pas !»",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [
      {
        text:
          "L’usine de St-Thomas se démarque par le dynamisme de ses gens et l’excellence de ses opérations. Faisant fièrement partie des usines du chef de file mondial qu’est Produits forestiers Resolu, elle bénéficie de la force du groupe tout en demeurant accessible et à l’écoute de ses employés."
      },
      {
        text:
          "Appuyée par des équipements modernes et des procédés qui optimisent la matière première si précieuse, l’usine de St-Thomas offre un milieu de travail sécuritaire, des défis stimulants et l’opportunité de développer vos compétences. Joignez-vous à nous ! "
      }
    ],
    imageList: [
      {
        src: "../../../../images-0.jpg"
      },
      {
        src: "../../../../images-1.jpg"
      },
      {
        src: "../../../../images-2.png"
      }
    ]
  },
  {
    ville: "Saint-Prime",
    sousPageIndex: "Saint-Prime",
    address: "101, rue du Parc-Industriel, Saint-Prime (Québec) G8J 1H3",
    goToGoogle:
      "https://www.google.ca/maps/dir//101+Rue+du+Parc-Industriel,+Saint-Prime,+QC+G8J+1Y6/@48.5916763,-72.323252,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc201a22a5e987b:0xa5695a066f668e3c!2m2!1d-72.3188746!2d48.5916693!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Larouche",
    sousPageIndex: "Larouche",
    address: "900, chemin du Lac-Hippolyte, Larouche (Québec) G0W 1Z0",
    goToGoogle:
      "https://www.google.ca/maps/dir//900+Chemin+du+Lac+Hippolyte,+Larouche,+QC+G0W+1Z0/@48.4460482,-71.5132886,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc1d41030e1dd2f:0x59e58b26b530bb90!2m2!1d-71.5089112!2d48.4460412!3e0",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Alma",
    sousPageIndex: "Alma",
    address: "1100, rue Melançon Ouest, Alma (Québec) G8B 5W2",
    goToGoogle:
      "https://www.google.com/maps/dir//1100+Rue+Melan%C3%A7on+O,+Alma,+QC+G8B+5R7/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4cea763872498b1f:0xe60e17abb8336725?sa=X&ved=2ahUKEwjZ-Iyh0JfvAhVZGVkFHckVCrIQwwUwAHoECAUQAw",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Dolbeau",
    sousPageIndex: "Dolbeau",
    address: "1, 4e Avenue, Dolbeau-Mistassini (Québec) G8L 2R4",
    goToGoogle:
      "https://www.google.com/maps/dir//1+4e+Av,+Dolbeau-Mistassini,+QC+G8L+1W3/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4ce9f159c6934373:0xf0b1baa4b9ed4343?sa=X&ved=2ahUKEwig94W80JfvAhXRFFkFHcjFAdsQwwUwAHoECAUQAw",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "Kénogami",
    sousPageIndex: "Kenogami",
    address: "3750, rue Champlain, Saguenay (Québec) G7X 1M1",
    goToGoogle:
      "https://www.google.com/maps/dir//3750+Rue+de+Champlain,+Saguenay,+QC/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4cc02903bf3c6233:0x5bc3c09a065692e6?sa=X&ved=2ahUKEwjV_qjV0JfvAhWaMlkFHbFLBlMQwwUwAHoECAUQAw",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  },
  {
    ville: "St-Félicien (Kraft)",
    sousPageIndex: "St-Felicien",
    address: "4000, Rte chemin Saint-Eusèbe, Saint-Félicien (Québec) G8K 2R6",
    goToGoogle:
      "https://www.google.com/maps/dir/46.8325302,-71.2451591/4000+Rte+chemin+Saint-Eus%C3%A8be+Saint-F%C3%A9licien+(Qu%C3%A9bec)+G8K+2R6+Canada/@47.7800143,-72.6343166,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x4cc207f72845e17f:0xfd511746e864bc24!2m2!1d-72.5147824!2d48.7448961",

    paragrapheList: [
      {
        text: ""
      },
      {
        text: ""
      },
      {
        text: ""
      }
    ],
    operationsList: [
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      },
      {
        title: "",
        text: ""
      }
    ],
    directeurMessage: "",
    candidatureSpontaneText: "",
    pourquoiTravaillerList: [{ text: "" }, { text: "" }, { text: "" }]
  }
];

export default NosUsine;

import PropTypes from "prop-types";
import React from "react";
import getMenuItems from "./Menu";
import { $Header } from "./SC_Header";
import { ReactSVG } from "react-svg";
import BurgerIcon from "./BurgerIcon";

const Header = ({ clientName, menuDatas }) => {
  const [menuState, setMenuState] = React.useState(false);
  return (
    <$Header menuFont={menuDatas} menuState={menuState}>
      {/* {console.log(menuDatas, "clientName")} */}
      <a href="/">
        <ReactSVG
          src={`../../../SVG/${menuDatas.logoUrl}`}
          className="logo"
          alt="logo"
        />
      </a>
      {/* <div className="menu">{getMenuItems(menuDatas.menuItems)}</div> */}

      {/* <BurgerIcon open={menuState} onClick={() => setMenuState(!menuState)} /> */}
    </$Header>
  );
};

Header.propTypes = {
  clientName: PropTypes.string,
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  clientName: ``,
  siteTitle: ``
};

export default Header;

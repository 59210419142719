import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "./styles/reset.scss";
import "./styles/layout.scss";
import clientData from "../client/data/data.json";
import NosUsine from "../client/data/nosUsine";

const BaseLayout = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [adressBox, setAdressBox] = React.useState(false);

  const closeAdressBox = () => {
    document.body.classList.remove("prevent-scroll");
    setAdressBox(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div style={{ display: `${isLoading ? "none" : "block"}` }}>
      <Header clientName={clientData.clientName} menuDatas={clientData.menu} />
      <div className="layout-container">{children}</div>
      {adressBox && (
        <>
          <div
            className="shadow-container"
            onClick={() => closeAdressBox()}
          ></div>
          <div className="adress-container">
            <div className="title">
              <h4>
                Adresses
                <strong
                  className="close-button"
                  onClick={() => closeAdressBox()}
                >
                  X
                </strong>
              </h4>
            </div>
            <div className="content">
              {NosUsine.map(elements => (
                <div className="units">
                  <span className="city">{elements.ville}</span>
                  <span className="adress">{elements.address}</span>
                  <a
                    className="cta"
                    target="_blank"
                    href={`${elements.goToGoogle}`}
                  >
                    Itineraire
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Footer
        setAdressBox={setAdressBox}
        siteTitle={clientData.siteTitle}
        siteUrl={clientData.siteUrl}
        footer={clientData.footer}
      />
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default BaseLayout;
